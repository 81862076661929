<template>
    <div>
        <!-- DESKTOP -->
        <v-row class="desktop-jumbotron" align="center">
            <v-col cols="7"></v-col>

            <v-col cols="5">
                <p class="jumbotron-text" id="jumbo-white-text">
                    No solo <br> imprimas...
                </p>
                <p class="jumbotron-text" id="jumbo-color-text">
                    ¡Impresiona!
                </p>
            </v-col>
        </v-row>

        <!-- MOBILE -->
        <v-row class="mobile-jumbotron" align="center">
            <v-col cols="12">
                <p class="jumbotron-text" id="jumbo-white-text-mobile">
                    No solo <br> imprimas...
                </p>
                <p class="jumbotron-text" id="jumbo-color-text-mobile">
                    ¡Impresiona!
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "Jumbotron",
}
</script>

<style scoped>
.desktop-jumbotron {
    height: 90vh;
    background-image: url('../../assets/Home/foto-portada.png');
    background-position: center;
    background-size: cover;
}

.mobile-jumbotron {
    display: none;
}

.jumbotron-text {
    font-size: 2em;
    text-align: center;
    line-height: 1em;
    font-weight: 600;
    color: white;
}

#jumbo-color-text {
    font-size: 2.25em;
    color: #892A43;
}

@media only screen and (max-width: 600px) {
    .desktop-jumbotron {
        display: none;
    }

    .mobile-jumbotron {
        display: block;
        height: 90vh;
        background-image: url('../../assets/Home/PortadaTROYA2.jpg');
        background-position: center;
        background-size: cover;
        padding-top: 60vh;
    }

    .jumbotron-text {
        font-size: 2em;
        text-align: center;
        line-height: 1em;
        font-weight: 600;
        color: white;
    }

    #jumbo-color-text-mobile {
        font-size: 2.25em;
        color: #892A43;
    }
}
</style>