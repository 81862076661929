<template>
    <v-col fluid no-gutters>
        <!-- DESKTOP -->
        <v-container fill-height fluid class="desktop">
            <!-- BLOG TITLE -->
            <v-row align="start" class="mt-0 ml-3">
                <v-card-title>
                    <h1 class="blog-list-title">BLOG</h1>
                </v-card-title>
            </v-row>

            <!-- LATEST BLOG ENTRY -->
            <v-row class="ma-4">
                <!-- LATEST BLOG IMG -->
                <v-col fill-height cols="12" md="5">
                    <v-row justify="end" class="mr-6">
                        <v-progress-circular
                            v-if="this.imgFile === null"
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                        <v-img v-else :src="this.imgFile[this.imgName]" width="200px"></v-img>
                    </v-row>
                </v-col>

                <!-- LATEST BLOG DATA -->
                <v-col cols="12" md="7">
                    <v-container fill-height fluid align="center">
                        <v-row justify="start" align="center">
                            <h3 class="d-flex mb-12" style="text-align: end;" id="first-entry-title">
                                {{ blogsDetails[0].title.toUpperCase() }}
                            </h3>
                        </v-row>

                        <v-row justify="end" align="center">
                            <p id="latest-description" class="d-flex" style="text-align: end;">
                                {{ blogsDetails[0].description.toUpperCase() }}
                            </p>
                            <p id="latest-date" class="d-flex" style="text-align: end;">
                                {{ blogsDetails[0].date.slice(0, 10) }}
                            </p>
                        </v-row>

                        <v-row justify="end" align="center">
                            <v-btn fab icon x-large class="buttonArrow mt-12" @click="handleClick(blogsDetails[0])">
                                <v-icon color="primary" x-large>mdi-arrow-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>

        <v-row class="mx-4 desktop mb-6">
            <v-col v-for="(blog, n) in blogsDetails" :key="n" class="d-flex child-flex" cols="12" md="4">
                <BlogPost_Card
                    :title="blog.title.toUpperCase()"
                    :description="blog.description"
                    :date="blog.date.slice(0, 10)"
                    :img="blog.img"
                    :document-id="blog.id"
                ></BlogPost_Card>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import firebase from "firebase";
import BlogPost_Card from "../Common_Components/BlogPost_Card";

export default {
    name: "BlogList.vue",
    components: {
        BlogPost_Card
    },
    metaInfo() {
        let completeTitle = 'Troya - Blog';
        let completeURL = 'https://www.troya.com.mx/blog';
        let imgURL = 'https://firebasestorage.googleapis.com/v0/b/troya-312e4.appspot.com/o/STICKY.png?alt=media&token=378d7682-a326-4c5b-aed8-6caf85c22556';

        return {
            title: completeTitle,
            meta: [
                { vmid: 'og:title', property: 'og:title', content: completeTitle },
                { vmid: 'twitter:title',  property: 'twitter:title', content: completeTitle },
                { vmid: 'og:image', property: 'og:image', content: imgURL },
                { vmid: 'twitter:image', property: 'twitter:image', content: imgURL },
                { vmid: 'og:url', property: 'og:url', content: completeURL },
                { vmid: 'twitter:url', property: 'twitter:url', content: completeURL },
            ]
        }
    },
    data: () => ({
        blogs:[],
        blogsDetails:[],
        imgFile: {}
    }),
    async created() {
        // gets all of the posts in the blog collection
        let blogCollection = firebase.firestore().collection('fl_content').orderBy('date', "desc");
        blogCollection.onSnapshot(
            (snap) => {
                this.blogs = [];
                for (let i = 0; i < snap.docs.length; i++) {
                    if(snap.docs[i].data()['_fl_meta_']['status'] === 'publish') {
                        this.blogs.push(snap.docs[i])
                    }
                }
                this.getBlogsDetails();
            }
        )
    },
    methods: {
        // gets the details from each post
        async getBlogsDetails() {
            // resets the blogsDetails array so we can start with a clean one
            this.blogsDetails = [];
            for (let i = 0; i < this.blogs.length; i++) {
                let date = new Date(this.blogs[i].data()['date']);
                date = date.toLocaleDateString()
                let title = await this.blogs[i].data()['title'];
                let img = await this.blogs[i].data()['cover'];
                let description = await this.blogs[i].data()['description'];
                let id = await this.blogs[i].id;
                this.blogsDetails.push({id: id, title: title, date: date, img: img, description: description});
            }

            await this.getImgName(this.blogsDetails[0].img[0]['path']);
        },

        handleClick(event) {
            this.$router.replace('/post/' + event.id);
        },

        async getImgName(imgPath) {
            let doc = await firebase.firestore().doc(imgPath).get();
            // console.log(doc.data()['file']);
            this.imgName = doc.data()['file'];
            await this.getImgFile();
        },

        async getImgFile() {
            // gs://troya-312e4.appspot.com/flamelink/media/sized/1920_9999_100
            await firebase.storage().ref('flamelink/media/sized/1920_9999_100/' + this.imgName).getDownloadURL().then(img => {
                // console.log(img);
                this.$set(this.imgFile, this.imgName, img);
            });
        }
    }
}

</script>

<style scoped>
.blog-list-title {
    color: #E13F2A;
}

#first-entry-title {
    color: #892A43;
    font-size: 3rem;
    line-height: 100%;
}

.main-blogpost-mobile h2 {
    color: #892A43;
    font-size: 3rem;
    line-height: 100%;
}


.main-blogpost-mobile p {
    color: #878787;
    font-size: 1.5rem;
    line-height: 100%;
    padding: 10%;
}

#latest-description {
    font-weight: bold;
    color: #2c3e50;
}

#latest-date {
    color: #2c3e50;
}

@media only screen and (max-width: 600px) {
    #first-entry-title {
        font-size: large;
        margin-top: 20px;
    }

    #latest-description {
        font-size: small !important;
    }
}

</style>
