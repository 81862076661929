<template>
    <v-container fluid>
        <divider></divider>
        <v-row style="overflow: hidden;"  class="mt-2 mr-n3 ml-n3" no-gutters>
            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col md="4" sm="12" style="color: black; background-color: white; margin: 0; padding: 0; height: auto;">
                    <v-card
                        flat
                        :elevation="hover ? 16 : 0"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden; border-radius: 0;"
                    >
                        <v-img
                            height="250"
                            class="imgCard rounded-0"
                            src="~@/assets/Home/Inicio 2.png"
                            gradient="rgba(0,0,0,.5), rgba(0,0,0,.5)"
                            style="border-radius: 0;"
                        >
                            <p class="tech-text">Somos obsesivos con nuestra calidad y entrega.</p>
                        </v-img>
                    </v-card>
                </v-col>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col md="4" sm="12" style="color: black; background-color: white; margin: 0; padding: 0; height: auto;">
                    <v-card
                        :elevation="hover ? 16 : 0"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            height="250"
                            class="imgCard ma0 pa-0"
                            src="@/assets/Home/20210903_094850.png"
                            gradient="rgba(0,0,0,.5), rgba(0,0,0,.5)"
                            style="border-radius: 0;"
                        >
                            <p class="tech-text">Usamos tecnología que no sólo imprime, impresiona.</p>
                        </v-img>
                    </v-card>
                </v-col>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col md="4" sm="12" style="color: black; background-color: white; margin: 0; padding: 0; height: auto;">
                    <v-card
                        :elevation="hover ? 16 : 0"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden; border-radius: 0;"
                    >
                        <v-img
                            height="250"
                            class="imgCard ma0 pa-0"
                            src="@/assets/Home/20210903_111212.png"
                            gradient="rgba(0,0,0,.5), rgba(0,0,0,.5)"
                            style="border-radius: 0;"
                        >
                            <p class="tech-text">Trabajamos en equipo, por eso somos los mejores.</p>
                        </v-img>
                    </v-card>
                </v-col>
            </v-hover>
        </v-row>
    </v-container>


</template>
<script>
import divider from "../Common_Components/divider";

export default {
    name: "TechnologySection",
    components: {
        divider
    }
}
</script>

<style scoped>
.tech-text {
    position: absolute;
    top: 70%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 700;
    font-size: 1.05em;
}

.imgCard:hover {
    transform: scale(1.1);
}
</style>