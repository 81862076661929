import Vue from 'vue';
import Vuetify from 'vuetify';
import styles from "./../styles/styles.css";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import '@mdi/font/css/materialdesignicons.css';

Vue.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
library.add(fas); // Include needed icons

Vue.use(Vuetify);
Vue.use(styles);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                text: '#020202',
                primary: '#892A43',
                accent: '#D37823',
                white: '#FFFFFF',
                grey: '#A9A9A9',
                darkGrey: '#878787',
                green: '#08B708',
                orange: '#E13F2A',
                yellow: '#FDC82C',
                aqua: '#01AAB2',
                purple: '#4014C2',
                pink: '#E1235B',
                blue: '#00628C',
                lightBlue: '#00b1C2',

            }
        }
    },
    icons: {
        iconfont: 'fa',
    },
});
