<template>
    <v-row>
        <v-col>
            <Jumbotron></Jumbotron>
            <Technology></Technology>
            <CorporateVideoSection></CorporateVideoSection>
            <BlogSection></BlogSection>
            <CompanyCultureSection></CompanyCultureSection>
            <ContactSection></ContactSection>
        </v-col>
    </v-row>
</template>

<script>
import Jumbotron from "./Jumbotron";
import Technology from "./Technology";
import BlogSection from "./BlogSection";
import CompanyCultureSection from "./CompanyCultureSection";
import ContactSection from "./ContactSection";
import CorporateVideoSection from "./CorporateVideoSection";

export default {
    name: "HomeScreen",
    components: {
        Jumbotron,
        Technology,
        BlogSection,
        CompanyCultureSection,
        ContactSection,
        CorporateVideoSection,
    },
    metaInfo() {
        return {
            title: 'Troya - Inicio',
            meta: [
                { vmid: 'description', name: 'description', content: 'No solo imprimas... IMPRESIONA!.' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/troya-312e4.appspot.com/o/STICKY.png?alt=media&token=378d7682-a326-4c5b-aed8-6caf85c22556' },
                { vmid: 'twitter:image', name: 'twitter:image', content: 'https://firebasestorage.googleapis.com/v0/b/troya-312e4.appspot.com/o/STICKY.png?alt=media&token=378d7682-a326-4c5b-aed8-6caf85c22556' },
                { vmid: 'og:url', name: 'og:url', content: 'https://www.troya.com.mx/' },
                { vmid: 'twitter:url', name: 'twitter:url', content: 'https://www.troya.com.mx/' },
            ]
        }
    },
}
</script>

<style scoped>

</style>