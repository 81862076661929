<template>
    <v-container fluid class="pa-3">
        <v-row>
            <v-col sm="12" md="6" class="ma-0 pa-0">
                <v-card class="accent rounded-0">
                    <v-card-title class="justify-center" align="center">
                        <v-row justify="center" align="center">
                            <h1 style="color: white" class="mt-12">NOSOTROS</h1>
                        </v-row>

                    </v-card-title>
                    <v-row>
                        <v-col class="pa-7 ma-8">
                            <p style="color: white; font-size: large" class="">
                                Somos una empresa con la convicción de ser distinguida como la mejor en impresión digital por su profesionalismo, actitud de servicio y puntualidad; ofrecemos soluciones integrales a través de nuestro equipo que consigue la satisfacción de nuestros clientes con base en la Norma ISO-9001.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" align="center" class="mb-12">
                            <v-img height="350" class="center about-us-img" src="~@/assets/Home/20210903_101827.jpg"></v-img>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col sm="12" md="6" class="ma-0 pa-0">
                <v-card class="orange rounded-0">
                    <v-card-title align="center">
                        <v-row justify="center" align="center">
                            <h1 style="color: white"  class="mt-12">NUESTRO LEMA</h1>
                        </v-row>
                    </v-card-title>
                    <v-row>
                        <v-col class="pa-7 ma-8">
                            <p style="color: white;font-size: large">
                                “No solo imprimas… ¡Impresiona!”, esta es la frase que define nuestra filosofía y forma de pensar. Con ella nos comprometemos a ofrecer no sólo un trabajo de calidad internacional, también a utilizar la mejor tecnología de impresión del mercado para el beneficio de nuestros clientes.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" align="center" class="mb-12">
                            <v-img height="350" class="center about-us-img" src="~@/assets/Home/Inicio 6.png"></v-img>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "CompanyCultureSection"
}
</script>

<style scoped>
.center h1 {
    margin-top: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: white;

}

.about-us-img {
    width: 80%;
}
</style>