<template>
    <v-container fluid>
        <v-row class="mt-12">
            <v-col style="color: #892A43" id="blogposts-title" align="left">
                <h1>BLOG</h1>
            </v-col>
        </v-row>

        <v-row class="desktop-blogs mb-6">
            <v-col v-for="(blog, n) in blogsDetails" :key="n" class="d-flex child-flex" cols="12" md="4">
                <BlogPost_Card
                    :title="blog.title.toUpperCase()"
                    :description="blog.description"
                    :date="blog.date.slice(0, 10)"
                    :img="blog.img"
                    :document-id="blog.id"
                ></BlogPost_Card>
            </v-col>
        </v-row>

        <v-row class="mb-12">
            <v-col md="4" cols="1"></v-col>
            <v-col md="4" cols="10">
                <router-link to="/blog" style="text-decoration: none;">
                    <v-btn class="yellow button primary--text" block >VER MÁS</v-btn>
                </router-link>
            </v-col>
            <v-col md="4" cols="1"></v-col>
        </v-row>
    </v-container>
</template>

<script>
import firebase from "firebase";
import BlogPost_Card from "../Common_Components/BlogPost_Card";

export default {
    name: "TechnologySection",
    components: {
        BlogPost_Card,
    },
    data: () => ({
        blogs:[],
        blogsDetails:[],
    }),
    async created() {
        //gets all of the posts in the blog collection
        let blogCollection = firebase.firestore().collection('fl_content').orderBy('date', "desc");
        blogCollection.onSnapshot(
            (snap) => {
                this.blogs = [];
                for (let i = 0; i < 3; i++) {
                    if(snap.docs[i].data()['_fl_meta_']['status'] === 'publish') {
                        this.blogs.push(snap.docs[i])
                    }
                }
                this.getBlogsDetails();
            }
        )
    },
    methods: {
        //gets the details from each post
        async getBlogsDetails() {
            //resets the blogsDetails array so we can start with a clean one
            this.blogsDetails = [];
            for (let i = 0; i < this.blogs.length; i++) {
                let date = new Date(this.blogs[i].data()['date']);
                date = date.toLocaleDateString()
                let title = await this.blogs[i].data()['title'];
                let img = await this.blogs[i].data()['cover'];
                let description = await this.blogs[i].data()['description'];
                let id = await this.blogs[i].id;
                this.blogsDetails.push({id: id, title: title, date: date, img: img, description: description});
            }
        },
        handleClick(event) {
            this.$router.replace('/post?postId=' + event.id);
        },
    }

}
</script>

<style scoped>
.desktop-blogs {
    margin-left: 30px;
    margin-right: 30px;
}

#blogposts-title {
    margin-left: 40px;
}

.button {
    color: #892A43;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .desktop-blogs {
        margin-left: 10px;
        margin-right: 10px;
    }

    #blogposts-title {
        margin-left: 0;
        align-items: center;
        alignment: center;
        text-align: center;
        align-self: center;
    }
}

</style>