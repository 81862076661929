<template>
    <v-row align="center" justify="center" style="height: 50vh;">
        <v-col justify="center" align="center">
            <iframe
                class="desktop"
                src="https://player.vimeo.com/video/641998608?h=0812fdbf64&byline=0&portrait=0"
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
            ></iframe>

            <iframe
                class="mobile"
                src="https://player.vimeo.com/video/641998608?h=0812fdbf64&byline=0&portrait=0"
                width="360"
                height="240"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
            ></iframe>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "CorporateVideoSection"
}
</script>

<style scoped>

.desktop {
    display: flex;
}

.mobile {
    display: none;
}

@media (max-width: 768px) {
    .desktop {
        display: none;
        position: fixed;
    }

    .mobile {
        display: block;
    }
}
</style>