import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/HomeScreen'
import BlogList from "../components/Blog/BlogList";
import Hermes from "../components/Hermes/Hermes";
import Solutions from "../components/Solutions/Solutions";
import TeamSection from "../components/Team/TeamSection";
import BlogPage from "../components/Blog/BlogPage";
import VueMeta from "vue-meta";

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    {path: '/', name: 'home', component: Home},
    {path: '/hermes', name: 'hermes', component: Hermes},
    {path: '/solutions', name: 'solutions', component: Solutions},
    {path: '/team', name: 'team', component: TeamSection},
    {path: '/blog', name: 'blog', component: BlogList},
    {path: '/post', name: 'post', component: BlogPage},
    {path: '/post/*', name: 'post', component: BlogPage},
]

// /,/hermes,/solutions,/team/,/blog,/post,/post?postId=IznGKXa02sVL8yUSZKYU

    // [
    // '/',
    // '/hermes',
    // '/solutions',
    // '/team',
    // '/blog',
    // '/post',
    // // '/post?postId=IznGKXa02sVL8yUSZKYU',
    // ],

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

export default router
