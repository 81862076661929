<template>
    <v-app class="app">
        <NavBar id="navbar"/>
        <MobileNavBar id="mobile-navbar"/>

        <v-main>
            <router-view></router-view>
        </v-main>

        <Footer></Footer>
    </v-app>
</template>

<script>
import NavBar from "./components/NavBar/NavBar";
import MobileNavBar from "./components/NavBar/MobileNavBar";
import Footer from "./components/Footer/Footer";

export default {
    components: {
        NavBar,
        MobileNavBar,
        Footer,
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#navbar {
    display: block;
}

#mobile-navbar {
    display: none;
}

/* Small devices (tablets, 768px and up) */
@media (max-width: 768px) {
    #navbar {
        display: none;
        position: fixed;
    }

    #mobile-navbar {
        display: block;
    }
}

</style>
