<template>
    <v-col fill-height>
        <!-- CONTACT TITLE -->
        <v-row>
            <v-col align="center" class="my-8 pa-5">
                <h1 style="color: #892A43; ">CONTÁCTANOS</h1>
            </v-col>
        </v-row>

        <!-- CONTACT INFO SECTION -->
        <v-row>
            <v-col cols="4">
                <a href="tel:+525552503697">
                    <v-btn icon color="primary">
                        <font-awesome-icon icon="phone" size="3x"></font-awesome-icon>
                    </v-btn>

                    <h4 class="mt-4" style="color: #2c3e50;">
                        55 5250 3697
                    </h4>
                </a>
            </v-col>

            <v-col cols="4">
                <a href="https://goo.gl/maps/q2qvtLQU9tiq7aXx7" target="_blank">
                    <v-btn icon color="primary">
                        <font-awesome-icon icon="map-marker-alt" size="3x"></font-awesome-icon>
                    </v-btn>

                    <h4 class="mt-4" style="color: #2c3e50;">
                        De Los Mayas 3 B, Sta Cruz Acatlan,
                        53150 Naucalpan de Juárez, Méx.
                    </h4>
                </a>
            </v-col>

            <v-col cols="4">
                <a href="https://wa.link/ixc9de" target="_blank">
                    <v-btn icon color="primary">
                        <v-img :src="this.whatsapp" width="50px" height="50px" style=""></v-img>
                    </v-btn>
                    <h4 class="mt-4" style="color: #2c3e50;">
                        55 3311 3296
                    </h4>
                </a>
            </v-col>
        </v-row>

        <!-- CONTACT FORM -->
        <v-row class="mb-6">
            <v-col align="center">
                <v-card color="#A9A9A9" class="rounded-0 post-card ma-8 pa-6">
                    <v-form>
                        <v-row>
                            <v-col fill-height cols="12" md="6" class="pr-6 pl-6">
                                <v-text-field
                                    label="Nombre"
                                    dense
                                    color="accent"
                                    background-color="white"
                                    type="text"
                                    outlined
                                ></v-text-field>

                                <v-text-field
                                    label="Mail"
                                    dense
                                    color="accent"
                                    background-color="white"
                                    type="email"
                                    outlined
                                ></v-text-field>

                                <v-text-field
                                    label="Teléfono"
                                    dense
                                    color="accent"
                                    background-color="white"
                                    type="phone"
                                    outlined
                                ></v-text-field>
                            </v-col>

                            <v-col fill-height cols="12" md="6" class="pr-6 pl-6">
                                <v-textarea
                                    outlined
                                    background-color="white"
                                    color="accent"
                                    label="Mensaje"
                                    no-resize
                                    height=""
                                    rows="6"
                                ></v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="1" md="4"></v-col>
                            <v-col cols="10" md="4" align="center">
                                <v-btn block class="yellow" style="color: #892A43; font-weight: bold">ENVIAR</v-btn>
                            </v-col>
                            <v-col cols="1" md="4"></v-col>
                        </v-row>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: "ContactanosSection",
    data() {
        return {
            whatsapp: null,
        }
    },
    mounted() {
        this.whatsapp = require("@/assets/whatsapp-brands.svg");
    }
}
</script>

<style scoped>

</style>