<template>
    <v-app>
        <v-row class="center desktop">
            <v-col align="left" id="team-header" cols="12" class="my-8">
                <h1 style="color: #E13F2A">COLABORADORES</h1>
                <h2 style="color: #892A43"> CONOCE A NUESTRO EQUIPO DE TRABAJO</h2>
            </v-col>
        </v-row>

<!--        <v-row class="center desktop" justify="center">-->
<!--            <v-col cols="12" md="6">-->
<!--                <v-row justify="center" align="center">-->
<!--                    <v-col cols="12">-->
<!--                        <v-img src="@/assets/Colaboradores/colaboradores.png"></v-img>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--            </v-col>-->
<!--        </v-row>-->

        <v-row class="center desktop" justify="center">
            <v-col cols="12" md="6">
                <v-row justify="center" align="center">
                    <v-col cols="12">
                        <h3 class="mx-3">
                            Tenemos un equipo de profesionales en varias áreas que permiten un correcto flujo de las
                            órdenes de trabajo, garantizando al cliente puntualidad, eficiencia y calidad en nuestros
                            productos y servicios.
                        </h3>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row justify="center" class="desktop">
            <v-col cols="12" class="my-8 pa-5">
                <h1 style="color: #892A43">¿Quiénes lo integramos?</h1>
            </v-col>
        </v-row>

        <v-row class="ma-3">
            <v-container fluid>
                <v-row dense class="desktop ma-3">
                    <v-col v-for="(item,i) in items" :key="i" cols="6" md="3">
                        <v-card class="rounded-0 my-3">
                            <v-img :src="item.src"></v-img>
<!--                            <v-card-title primary-title class="justify-center">-->
<!--                                <h4>{{ item.name }}</h4>-->
<!--                            </v-card-title>-->
<!--                            <v-card-text>-->
<!--                                <h3> {{ item.puesto }}</h3>-->
<!--                            </v-card-text>-->
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>


    </v-app>

</template>

<script>
export default {
    name: "ColaboradoresSection",
    metaInfo() {
        let completeTitle = 'Troya - Colaboradores';
        let completeURL = 'https://www.troya.com.mx/team';
        let imgURL = 'https://firebasestorage.googleapis.com/v0/b/troya-312e4.appspot.com/o/STICKY.png?alt=media&token=378d7682-a326-4c5b-aed8-6caf85c22556';

        return {
            title: completeTitle,
            meta: [
                { vmid: 'og:title', property: 'og:title', content: completeTitle },
                { vmid: 'twitter:title',  property: 'twitter:title', content: completeTitle },
                { vmid: 'og:image', property: 'og:image', content: imgURL },
                { vmid: 'twitter:image', property: 'twitter:image', content: imgURL },
                { vmid: 'og:url', property: 'og:url', content: completeURL },
                { vmid: 'twitter:url', property: 'twitter:url', content: completeURL },
            ]
        }
    },
    data: () => ({
        items: [
            {
                name: 'Eduardo Márquez',
                puesto: 'Director de Operaciones',
                src: require('@/assets/Colaboradores/Eduardo Márquez_Director de Operaciones.png')
            },
            {
                name: 'Gerardo Rodríguez',
                puesto: 'Director Comercial',
                src: require('@/assets/Colaboradores/Gerardo Rodríguez_Director Comercial.png')
            },
            {
                name: 'Hugo Contreras Aragón',
                puesto: 'Gerente de Producción',
                src: require('@/assets/Colaboradores/Hugo Contreras Aragón_Gerente de Producción.png')
            },
            {
                name: 'María del Carmen Prado Sandoval',
                puesto: 'Gerente Administrativo',
                src: require('@/assets/Colaboradores/María del Carmen Prado Sandoval_Gerente Administrativo.png')
            },
            {
                name: 'David González',
                puesto: 'Diseño',
                src: require('@/assets/Colaboradores/David González_Diseño.png')
            },
            {
                name: 'Ulises Villegas',
                puesto: 'Diseño',
                src: require('@/assets/Colaboradores/Ulises Villegas_Diseño.png')
            },
            {
                name: 'Eduardo Guerrero',
                puesto: 'Supervisor',
                src: require('@/assets/Colaboradores/Eduardo Guerrero_Supervisor.png')
            },
            {
                name: 'Gloria Piedras',
                puesto: 'Atención a Clientes',
                src: require('@/assets/Colaboradores/Gloria Piedras_Atención a Clientes.png')
            },
            {
                name: 'Catalina Evangelista Contreras',
                puesto: 'Recursos Humanos',
                src: require('@/assets/Colaboradores/Catalina Evangelista Contreras_Recursos Humanos.png')
            },
            {
                name: 'Minerva Ocampo Galván',
                puesto: 'Cobranza',
                src: require('@/assets/Colaboradores/Minerva Ocampo Galván_Cobranza.png')
            },
            {
                name: 'Alejandra Meléndez Márquez',
                puesto: 'Compras',
                src: require('@/assets/Colaboradores/Alejandra Meléndez Márquez_Compras.png')
            },
            {
                name: 'Adolfo Merchand',
                puesto: 'Asesor Comercial',
                src: require('@/assets/Colaboradores/Adolfo Merchand_Asesor Comercial.png')
            },
            {
                name: 'Arianna Magdaleno Granados',
                puesto: 'Recepción',
                src: require('@/assets/Colaboradores/Arianna Magdaleno Granados_Recepción.png')
            },
            {
                name: 'Lourdes Corvi',
                puesto: 'Logística',
                src: require('@/assets/Colaboradores/Lourdes Corvi_Logística.png')
            },
            {
                name: 'Jaime Cirilo Santiago',
                puesto: 'Logística',
                src: require('@/assets/Colaboradores/Jaime Cirilo Santiago_Logística.png')
            },
            {
                name: 'Daniel Márquez',
                puesto: 'Transporte',
                src: require('@/assets/Colaboradores/Daniel Márquez_Transporte.png')
            },
        ],
    }),
}
</script>

<style scoped>
.center h4 {
    text-align: center;
}

.desktop {
    display: flex;
}

#team-header {
    margin-left: 50px;
}

@media only screen and (max-width: 600px) {
    #team-header {
        margin-top: 0 !important;
        margin-left: 0;
        align-self: center;
        align-items: center;
        align-content: center;
        text-align: center;
    }

    #team-header h2 {
        font-size: 1em;
    }
}
</style>