<template>
    <v-app>
        <!--DESKTOP-->
        <v-row class="desktop">
            <v-col cols="3" class="mt-12 ml-12">
                <v-img
                    src="@/assets/Logos/Hermes-Logo.png"
                ></v-img>
            </v-col>
        </v-row>

        <v-row class="center desktop">
            <v-col align="left" cols="12" class="mb-8 ml-9 mt-n5">
                <h1 style="color: #892A43">IMPRESIÓN DE DISPLAY RÍGIDO</h1>
            </v-col>
        </v-row>

        <v-row class="mx-5 mb-6 desktop" justify="center">
            <v-col cols="5">
                <v-row justify="center" align="center">
                    <v-col cols="12">
                        <iframe
                            src="https://player.vimeo.com/video/646972545?h=c7c4cae2da"
                            width="640"
                            height="360"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </v-col>
                </v-row>
<!--                <v-row justify="center" align="center">-->
<!--                    <v-col cols="12">-->
<!--                        <v-img src="@/assets/Hermes/Foto 1.png"></v-img>-->
<!--                    </v-col>-->
<!--                </v-row>-->
            </v-col>

            <v-col cols="7">
                <v-container fill-height>
                    <v-row justify="center" align="center">
                        <v-col cols="12">
                            <h2 style="font-weight: normal">
                                ¿Tienes un magno evento donde requieres rígidos de alta calidad?
                            </h2>
                            <br>
                            <h2>
                                ¡Nosotros tenemos la tecnología y experiencia para hacerlos!
                            </h2>
                            <br>
                            <h2 style="font-weight: normal">
                                Impresionamos con rígidos en resolución óptima de hasta 1200 x 600 dpi‘s y corte en cama
                                plana.
                            </h2>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>

        <v-row style="overflow: hidden" class="center pa-0 ma-0 desktop">
            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col cols="4" class="ma0 pa-0" style="color: black">
                    <v-card
                        :elevation="hover ? 16 : 2"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            class="imgCard ma0 pa-0"
                            src="@/assets/Hermes/Foto 2.png"
                            height="300"
                        ></v-img>
                    </v-card>
                </v-col>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col cols="4" class="ma0 pa-0">
                    <v-card
                        :elevation="hover ? 16 : 2"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            class="imgCard ma0 pa-0"
                            src="@/assets/Hermes/Foto 3.png"
                            height="300"
                        >
                        </v-img>
                    </v-card>
                </v-col>
            </v-hover>

            <v-hover
                v-slot="{ hover }"
                open-delay="200"
            >
                <v-col cols="4" class="ma0 pa-0">
                    <v-card
                        :elevation="hover ? 16 : 2"
                        :class="{ 'on-hover': hover }"
                        class="ma-0 pa-0 rounded-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            class="imgCard ma0 pa-0"
                            src="@/assets/Hermes/Foto 4.png"
                            height="300"
                        ></v-img>
                    </v-card>
                </v-col>
            </v-hover>


        </v-row>

        <v-row class="center desktop">
            <v-col align="center" cols="6" class="my-8 pa-5">
                <h1 style="color: #892A43">¿QUÉ OFERTA TENEMOS PARA TI? </h1>
            </v-col>

        </v-row>

        <v-row class="mx-5 mb-6 desktop">

            <v-col cols="7">
                <v-container fill-height>
                    <v-row justify="start" align="start">
                        <ul>
                            <li style="color: #892A43; text-align: start;">
                                <h2>Materiales especiales</h2>
                            </li>
                            <li style="color: #892A43; text-align: start;">
                                <h2>Unifilas</h2>
                            </li>
                            <li style="color: #892A43; text-align: start;">
                                <h2>Tend Card</h2>
                            </li>
                            <li style="color: #892A43; text-align: start;">
                                <h2>Materiales especiales</h2>
                            </li>
                        </ul>
                    </v-row>

                </v-container>
            </v-col>

            <v-col cols="5">

                <v-row justify="center" align="right" class="mr-4">
                    <v-col cols="12">
                        <v-img src="@/assets/Hermes/Foto 5.png"></v-img>
                    </v-col>
                </v-row>

            </v-col>


        </v-row>


        <!--MOBILE-->
        <v-row class="mobile">
            <v-col cols="12" class="ma-5">
                <v-img
                    src="@/assets/Logos/Hermes-Logo.png"
                    id="hermes-logo"
                ></v-img>
            </v-col>
        </v-row>

        <v-row class="center mobile">
            <v-col align="center" class="">
                <h3 style="color: #892A43">IMPRESIÓN DE DISPLAY RÍGIDO</h3>
            </v-col>
        </v-row>

        <v-row class="mx-0 mb-6 mobile" justify="center">

            <v-col>
                <v-row justify="center">

                    <v-col align="center">
                        <iframe src="https://player.vimeo.com/video/646972545?h=c7c4cae2da" width="360" height="240" frameborder="0"
                                allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    </v-col>
<!--                    <v-col align="center">-->
<!--                        <v-img class="mx-auto" src="@/assets/Hermes/Foto 1.png"></v-img>-->
<!--                    </v-col>-->

                </v-row>


            </v-col>

            <v-col>

                <v-container fill-height>

                    <v-row justify="center" align="center">

                        <v-col cols="12">


                            <h2 style="font-weight: normal">
                                ¿Tienes un magno evento donde requieres rígidos de alta calidad?
                            </h2>
                            <br>
                            <h2>
                                ¡Nosotros tenemos la tecnología y experiencia para hacerlos!
                            </h2>

                            <br>
                            <h2 style="font-weight: normal">
                                Impresionamos con rígidos en resolución óptima de hasta 1200 x 600 dpi‘s y corte en cama
                                plana.
                            </h2>

                        </v-col>

                    </v-row>

                </v-container>

            </v-col>
            <v-spacer></v-spacer>
        </v-row>

        <v-row style="overflow: hidden" class="center pa-0 ma-0 mobile">
            <v-hover
                v-slot="{ hover }"
                open-delay="200"
            >
                <v-col class="ma0 pa-0" style="color: black">
                    <v-card
                        :elevation="hover ? 16 : 2"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            class="imgCard ma0 pa-0"
                            src="@/assets/Hermes/Foto 2.png"
                        >
                        </v-img>
                    </v-card>
                </v-col>
            </v-hover>

            <v-hover
                v-slot="{ hover }"
                open-delay="200"
            >
                <v-col class="ma0 pa-0">

                    <v-card
                        :elevation="hover ? 16 : 2"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden;"
                    >
                        <v-img

                            class="imgCard ma0 pa-0"
                            src="@/assets/Hermes/Foto 3.png"

                        >


                        </v-img>

                    </v-card>

                </v-col>

            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col class="ma0 pa-0">
                    <v-card
                        :elevation="hover ? 16 : 2"
                        :class="{ 'on-hover': hover }"
                        class="ma-0 pa-0 rounded-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            class="imgCard ma0 pa-0"
                            src="@/assets/Hermes/Foto 4.png"
                        >
                        </v-img>
                    </v-card>
                </v-col>
            </v-hover>


        </v-row>

        <v-row class="center mobile">
            <v-col align="center" class="my-8 pa-5">
                <h3 style="color: #892A43">¿QUÉ OFERTA TENEMOS PARA TI? </h3>
            </v-col>

        </v-row>

        <v-row class="mx-5 mb-6 mobile">

            <v-col>
                <v-container fill-height>
                    <v-row justify="center" align="center">
                        <v-col>

                            <h3 style="color: #892A43">
                                · Standee Especial<br>
                                · Unifilas<br>
                                · Tend Card<br>
                                · Materiales especiales<br>

                            </h3>

                        </v-col>
                    </v-row>

                </v-container>
            </v-col>

            <v-col>

                <v-row justify="center">
                    <v-col class=" pa-0 ml-1">
                        <v-img class="ma-0 pa-0" src="@/assets/Hermes/Foto 5.png"></v-img>
                    </v-col>
                </v-row>

            </v-col>


        </v-row>


    </v-app>

</template>

<script>
export default {
    name: "HermesSection",
    metaInfo() {
        let completeTitle = 'Troya - Hermes';
        let completeURL = 'https://www.troya.com.mx/hermes';
        let imgURL = 'https://firebasestorage.googleapis.com/v0/b/troya-312e4.appspot.com/o/STICKY.png?alt=media&token=378d7682-a326-4c5b-aed8-6caf85c22556';

        return {
            title: completeTitle,
            meta: [
                {vmid: 'og:title', property: 'og:title', content: completeTitle},
                {vmid: 'twitter:title', property: 'twitter:title', content: completeTitle},
                {vmid: 'og:image', property: 'og:image', content: imgURL},
                {vmid: 'twitter:image', property: 'twitter:image', content: imgURL},
                {vmid: 'og:url', property: 'og:url', content: completeURL},
                {vmid: 'twitter:url', property: 'twitter:url', content: completeURL},
            ]
        }
    },
}
</script>

<style scoped>
#hermes-logo {
    width: 90%;
}

.imgCard:hover {
    transform: scale(1.1);

}

.desktop {
    display: flex;
}

.mobile {
    display: none;
}

@media (max-width: 768px) {
    .desktop {
        display: none;
        position: fixed;
    }

    .mobile {
        display: block;
    }
}


</style>