<template>
    <div>
        <v-app-bar color="white" elevation="0" fixed height="90px">
            <v-row justify="space-around" align="center">
                <a href="https://www.troya.com.mx" style="width: 0;">
                    <img
                        src="../../assets/Troya-Logo.png"
                        class="logo-format mt-2 ml-2"
                        alt="logo"
                    >
                </a>

                <v-spacer></v-spacer>

                <v-btn fab icon color="white" @click.stop="drawer =!drawer" class="mt-3 mr-2">
                    <v-icon color="#892A43" large>mdi-menu</v-icon>
                </v-btn>
            </v-row>
        </v-app-bar>

        <!--List of nav options-->
        <v-navigation-drawer
            v-model="drawer"
            app
            temporary
            right
            color="primary"
            width="100%"
            height="100vh"
            fixed
        >
            <v-container class="primary" v-if="drawer">
                <v-list
                    align="end"
                >
                    <v-icon @click.stop="drawer =!drawer" color="white" class="mt-5 mr-4 mb-4">mdi-close</v-icon>
                    <v-list-item-group
                        align="start"
                    >
                        <router-link to="/">
                            <v-list-item>
                                <v-list-item-title class="drawer-item">INICIO</v-list-item-title>
                            </v-list-item>
                        </router-link>

                        <router-link to="/hermes">
                            <v-list-item>
                                <v-list-item-title class="drawer-item">HERMES</v-list-item-title>
                            </v-list-item>
                        </router-link>

                        <router-link to="/solutions">
                            <v-list-item>
                                <v-list-item-title class="drawer-item">SOLUCIONES</v-list-item-title>
                            </v-list-item>
                        </router-link>

                        <router-link to="/team">
                            <v-list-item>
                                <v-list-item-title class="drawer-item">COLABORADORES</v-list-item-title>
                            </v-list-item>
                        </router-link>

                        <router-link to="/blog">
                            <v-list-item>
                                <v-list-item-title class="drawer-item">BLOG</v-list-item-title>
                            </v-list-item>
                        </router-link>

                    </v-list-item-group>
                </v-list>
                <v-card color="primary" height="350px" elevation="0"></v-card>
                <template class="icons">
                    <div class="mt-auto">
                        <v-btn fab icon elevation="0" color="transparent" target="_blank"
                               href="https://www.facebook.com/TroyaDigitalPrinting/">
                            <v-icon color="white" large>mdi-facebook</v-icon>
                        </v-btn>
                        <v-btn fab icon elevation="0" color="transparent" target="_blank"
                               href="https://www.instagram.com/troyadigitalprinting/">
                            <v-icon color="white" large>mdi-instagram</v-icon>
                        </v-btn>
                        <v-btn fab icon elevation="0" color="transparent" target="_blank"
                               href="https://www.linkedin.com/company/troyadigitalprinting/">
                            <v-icon color="white" large>mdi-linkedin</v-icon>
                        </v-btn>
<!--                        <v-btn fab icon elevation="0" color="transparent" target="_blank"-->
<!--                               href="https://www.facebook.com/TroyaDigitalPrinting/">-->
<!--                            <v-icon color="white" large>mdi-youtube</v-icon>-->
<!--                        </v-btn>-->
                    </div>
                </template>
            </v-container>
        </v-navigation-drawer>

    </div>
</template>

<script>
export default {
    name: "MobileNavBar.vue",
    data() {
        return {
            drawer: false,
            showServices: false,
        }
    }
}
</script>

<style scoped>
.logo-format {
    height: 65px;
}

.drawer-item {
    color: white;
}

.icons {
    align-content: space-evenly;
    position: absolute;
    vertical-align: bottom;
    flex: 0;
}

</style>
