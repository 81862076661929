<template>
    <v-container>
        <v-row>
            <router-link to="/blog" style="text-decoration: none">
                <v-btn icon x-large>
                    <v-icon color="primary" large flat fba icon>mdi-chevron-left</v-icon>
                    <h3>BLOGS</h3>
                </v-btn>
            </router-link>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" md="9">
                <h1 style="color: #892A43; text-align: left;" class="mb-6 mt-6">{{ this.title }}</h1>

                <v-progress-circular
                    v-if="this.imgFile === null"
                    indeterminate
                    color="#892A43"
                ></v-progress-circular>
                <v-img v-else :src="this.imgFile[this.imgName]" width="100%"></v-img>

                <span style="text-align: left; margin-top: 50px;" v-html="this.content"></span>
            </v-col>

            <v-col cols="12" md="3" class="pl-4 pt-10 description-col">
                <v-container style="">
                    <h4 style="font-weight: bold; text-align: left" class="mb-1">Descripción</h4>
                    <p style="text-align: left;" class="mt-1">{{ this.description }}</p>

                    <h4 style="font-weight: bold;text-align: left" class="mb-1">Autor</h4>
                    <p style="text-align: left" class="mt-1">{{ this.author }}</p>

                    <h4 style="font-weight: bold;text-align: left" class="mb-1">Categoría</h4>
                    <p style="text-align: left" class="mt-1">{{ this.category }}</p>

                    <h4 style="font-weight: bold;text-align: left" class="mb-1">Fecha</h4>
                    <p style="text-align: left" class="mt-1">{{ this.date }}</p>
                </v-container>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import firebase from "firebase";

export default {
    name: "BlogPage",
    metaInfo() {
        let completeTitle = 'Troya - ' + this.title;
        let completeURL = 'https://www.troya.com.mx/post/' + this.postId;

        return {
            title: completeTitle,
            meta: [
                { vmid: 'description', name: 'description', content: this.description },
                { vmid: 'og:description', property: 'og:description', content: this.description },
                { vmid: 'twitter:description', property: 'twitter:description', content: this.description },
                { vmid: 'og:title', property: 'og:title', content: completeTitle },
                { vmid: 'twitter:title',  property: 'twitter:title', content: completeTitle },
                { vmid: 'og:image', property: 'og:image', content: this.imgURL },
                { vmid: 'twitter:image', property: 'twitter:image', content: this.imgURL },
                { vmid: 'og:url', property: 'og:url', content: completeURL },
                { vmid: 'twitter:url', property: 'twitter:url', content: completeURL },
            ]
        }
    },
    data: () => ({
        postDetails: [],
        content: '',
        title: '',
        date: '',
        img: '',
        description: '',
        author: '',
        category: '',
        imgFile: {},
        imgName: {},
        imgURL: ''
    }),
    async created() {
        this.$meta().refresh();

        // console.log(this.$route);
        // console.log(this.$route.path.toString());
        // console.log(this.$route.path.toString().substring(6, 26));

        this.postId = this.$route.path.toString().substring(6, 26);

        await firebase.firestore().collection('fl_content').doc(this.postId).get().then(async (snapshot) => {
            let post = snapshot.data();

            // console.log(post)
            let date = new Date(post['date']);
            this.date = date.toLocaleDateString()
            this.title = post['title'];
            this.img = post['cover'];
            this.content = post['content'];
            this.description = post['description'];
            this.author = post['author'];
            this.category = post['category']

            await this.getImgName(this.img[0]['path']);
        });
    },
    methods: {
        async getImgName(imgPath) {
            let doc = await firebase.firestore().doc(imgPath).get();
            this.imgName = doc.data()['file'];
            await this.getImgFile();
        },
        async getImgFile() {
            // gs://troya-312e4.appspot.com/flamelink/media/sized/1920_9999_100
            await firebase.storage().ref('flamelink/media/sized/1920_9999_100/' + this.imgName).getDownloadURL().then(img => {
                this.imgURL = img;
                this.$set(this.imgFile, this.imgName, img);
            });
        }
    }
}
</script>

<style scoped>
.description-col {
    position: fixed;
    right: 0;
}

@media only screen and (max-width: 600px) {
    .description-col {
        position: static;
    }
}
</style>
