<template>
    <div>
        <v-app-bar flat height="100%" app fixed>
            <a href="https://www.troya.com.mx">
                <v-col class="logo"></v-col>
            </a>
            <v-btn class="ml-4" fab icon elevation="0" target="_blank"
                   href="https://www.facebook.com/TroyaDigitalPrinting/">
                <v-icon color="#892A43" large>mdi-facebook</v-icon>
            </v-btn>
            <v-btn fab icon elevation="0" target="_blank" href="https://www.instagram.com/troyadigitalprinting/">
                <v-icon color="#892A43" large>mdi-instagram</v-icon>
            </v-btn>
            <v-btn fab icon elevation="0" target="_blank" href="https://www.linkedin.com/company/troyadigitalprinting/">
                <v-icon color="#892A43" large>mdi-linkedin</v-icon>
            </v-btn>
            <!--      <v-btn fab icon elevation="0" target="_blank" href="https://www.facebook.com/TroyaDigitalPrinting/"><v-icon color="#892A43" large>mdi-youtube</v-icon></v-btn>-->
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn fab icon @click.stop="drawer =!drawer" elevation="0">
                <v-icon color="#892A43" large>mdi-menu</v-icon>
            </v-btn>
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            app
            temporary
            right
            color="primary"
            width="20%"
            height="100vh"
        >
            <v-list nav align="end">
                <v-icon @click.stop="drawer =!drawer" color="white" class="mt-6 mr-4 mb-4">mdi-close</v-icon>
                <v-list-item-group align="start">
                    <router-link to="/">
                        <v-list-item>
                            <v-list-item-title class="drawer-item">INICIO</v-list-item-title>
                        </v-list-item>
                    </router-link>

                    <router-link to="/hermes">
                        <v-list-item>
                            <v-list-item-title class="drawer-item">HERMES</v-list-item-title>
                        </v-list-item>
                    </router-link>

                    <router-link to="/solutions">
                        <v-list-item>
                            <v-list-item-title class="drawer-item">SOLUCIONES</v-list-item-title>
                        </v-list-item>
                    </router-link>

                    <router-link to="/team">
                        <v-list-item>
                            <v-list-item-title class="drawer-item">COLABORADORES</v-list-item-title>
                        </v-list-item>
                    </router-link>

                    <router-link to="/blog">
                        <v-list-item>
                            <v-list-item-title class="drawer-item">BLOG</v-list-item-title>
                        </v-list-item>
                    </router-link>
                </v-list-item-group>
            </v-list>
            <template v-slot:append class="icons">
                <div class="my-3">
                    <v-btn fab icon elevation="0" color="transparent" target="_blank"
                           href="https://www.facebook.com/TroyaDigitalPrinting/">
                        <v-icon color="white" large>mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn fab icon elevation="0" color="transparent" target="_blank"
                           href="https://www.instagram.com/troyadigitalprinting/">
                        <v-icon color="white" large>mdi-instagram</v-icon>
                    </v-btn>
                    <v-btn fab icon elevation="0" color="transparent" target="_blank"
                           href="https://www.linkedin.com/company/troyadigitalprinting/">
                        <v-icon color="white" large>mdi-linkedin</v-icon>
                    </v-btn>
                    <!--          <v-btn fab icon elevation="0" color="transparent" target="_blank" href="https://www.facebook.com/TroyaDigitalPrinting/">-->
                    <!--            <v-icon color="white" large>mdi-youtube</v-icon>-->
                    <!--          </v-btn>-->
                </div>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: "NavBar.vue",
    data: () => ({
        drawer: false
    }),
}
</script>

<style scoped>
.logo {
    background-image: url("../../assets/Troya-Logo.png");
    background-size: contain;
    background-position: center;
    height: 100px;
    width: 140px;
}

.icons {
    align-content: space-evenly;
}

.drawer-item {
    color: white;
    font-weight: bold;
}
</style>

