<template>
    <v-responsive :aspect-ratio="1/1">
        <v-card height="100%" style="position: relative" class="darkGrey rounded-0 card1" @click="handleClick()" >
            <v-container fill-height>
                <v-row justify="center" align="center" class="ml-2">
                    <v-col cols="4">
                        <v-progress-circular
                            v-if="this.imgFile === null"
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                        <v-img v-else :src="this.imgFile[this.imgName]" height="100"></v-img>
                    </v-col>

                    <v-col cols="8" class="flex">
                        <h4 class="titleBlog blogTitle" style="text-align: left; font-weight: bold;">{{ this.title }}</h4>
                        <h5 style="text-align: left; color: #892A43; font-weight: bold;">{{ this.date }}</h5>
                    </v-col>
                </v-row>

                <v-row class="ml-4 mr-4 mt-4">
                    <p style="color: white; text-align: left;">
                        {{ this.description }}
                    </p>
                </v-row>

                <v-row class="ml-4 mr-3 mt-4" justify="end">
                    <v-btn icon x-large class="mt-12">
                        <v-icon color="white" x-large>mdi-arrow-right</v-icon>
                    </v-btn>
                </v-row>
            </v-container>
        </v-card>
    </v-responsive>
</template>

<script>
import firebase from "firebase";

export default {
    name: "BlogPost_Card",
    props: {
        title: String,
        date: String,
        description: String,
        img: String,
        documentId: String
    },
    data() {
        return {
            imgName: '',
            imgFile: {}
        }
    },
    async mounted() {
        await this.getImgName(this.img[0]['path']);
    },
    methods:{
        handleClick() {
            this.$router.replace('/post/' + this.documentId);
        },
        async getImgName(imgPath) {
            let doc = await firebase.firestore().doc(imgPath).get();

            this.imgName = doc.data()['file'];
            await this.getImgFile();
        },
        async getImgFile() {
            // gs://troya-312e4.appspot.com/flamelink/media/sized/1920_9999_100
            await firebase.storage().ref('flamelink/media/sized/1920_9999_100/' + this.imgName).getDownloadURL().then(img => {
                // console.log(img);
                this.$set(this.imgFile, this.imgName, img);
            });
        }
    }
}
</script>

<style scoped>
.card1 {
    height: auto;
}

.blogTitle {
    color: #FDC82C;
}


.card1:hover {
    transform: scale(0.98);
}
</style>
