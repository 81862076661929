import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyAB5l9HEgrP4rN97RdekPpZxnw3Fn4W0q4",
    authDomain: "troya-312e4.firebaseapp.com",
    projectId: "troya-312e4",
    storageBucket: "troya-312e4.appspot.com",
    messagingSenderId: "777643491417",
    appId: "1:777643491417:web:6bab067acacba8f1477148",
    measurementId: "G-FYGTPB94MN"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const db = firebase.firestore();



