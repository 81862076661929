<template>
    <v-row class="divider-row">
    </v-row>
</template>

<script>
export default {
    name: "divider"
}
</script>

<style scoped>
.divider-row {
    height: 80px;
    background-color: #892A43;
}
</style>