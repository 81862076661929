<template>
    <v-container fluid>
        <!-- DESKTOP -->
        <v-row class="center desktop">
            <v-col align="left" cols="5" class="my-8 pa-5 ml-6">
                <h1 style="color: #E13F2A">SOLUCIONES</h1>
                <h2 style="color: #892A43"> ¿QUÉ HACEMOS Y CÓMO LO HACEMOS?</h2>
            </v-col>
        </v-row>

        <v-row class="mx-5 mb-6 ml-3 desktop" justify="center">
            <v-col cols="7">
                <v-row justify="center" align="center">
                    <v-col cols="12">
                        <iframe
                            src="https://player.vimeo.com/video/646965303?h=90c1efe31"
                            width="640"
                            height="360"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                        <!--                        <v-img src="@/assets/Soluciones/large-inkjet-printer-working-multicolor-on-vinyl-banner.jpg"></v-img>-->
                    </v-col>
                </v-row>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="5">
                <v-container fill-height class="center">
                    <v-row justify="center" align="center">
                        <v-col cols="12">
                            <h2 style="font-weight: normal">
                                Nuestra tecnología de última generación nos permite ofrecer una calidad de impresión a
                                nivel internacional, agilizando procesos de producción y entrega, pero sobre todo
                                ofreciendo una auténtica experiencia visual para el consumidor de nuestros clientes.
                            </h2>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <v-row class="mx-5 mb-6 mr-1 desktop" justify="center">
            <v-col cols="7">
                <v-container fill-height>
                    <v-row justify="center" align="center">
                        <v-col cols="12">
                            <h2 style="font-weight: normal">
                                Redefinimos el modelo de servicio, integrando a nuestro equipo de trabajo especializado,
                                que hace efectiva la comunicación entre el cliente y el proceso de producción, brindando
                                de esta forma una experiencia satisfactoria.

                            </h2>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="5">
                <v-container fill-height>
                    <v-row justify="center" align="center">
                        <v-col cols="12">
                            <v-img src="@/assets/Soluciones/DSC_0078.jpg"></v-img>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <v-row class="center desktop">
            <v-col align="left" cols="12" class="my-8 pa-5 ml-6">
                <h1 style="color: #892A43"> ¿QUÉ OFERTA TENEMOS PARA TI? </h1>
            </v-col>
        </v-row>

        <v-row class="mx-5 mb-12 desktop" justify="center">
            <v-col cols="5">
                <v-row justify="center" align="center">
                    <v-col cols="12">
                        <v-img src="@/assets/Soluciones/DSC_0091.jpg"></v-img>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="7">
                <v-container fill-height>
                    <v-row justify="center" align="center">
                        <ul>
                            <li style="color: #892A43; text-align: start;">
                                <h3>Lona Mesh</h3>
                            </li>
                            <li style="color: #892A43; text-align: start;">
                                <h3>Lona Front</h3>
                            </li>
                            <li style="color: #892A43; text-align:  start;">
                                <h3>Lona</h3>
                            </li>
                            <li style="color: #892A43; text-align: start;">
                                <h3>Vinil adhesivo</h3>
                            </li>
                            <li style="color: #892A43; text-align: start;">
                                <h3>Vinil estático</h3>
                            </li>
                            <li style="color: #892A43; text-align:  start;">
                                <h3>Vinil microperforado</h3>
                            </li>
                            <li style="color: #892A43; text-align:  start;">
                                <h3>Telas</h3>
                            </li>
                            <li style="color: #892A43; text-align:  start;">
                                <h3>Rotulación</h3>
                            </li>
                            <li style="color: #892A43; text-align: start;">
                                <h3>Estireno</h3>
                            </li>
                            <li style="color: #892A43; text-align: start;">
                                <h3>Backlight</h3>
                            </li>
                        </ul>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <v-row class="pa-0 ma-n3 desktop">
            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col md="4" cols="12" class="ma0 pa-0" style="color: black">
                    <v-card
                        :elevation="hover ? 16 : 0"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            class="imgCard ma0 pa-0"
                            src="@/assets/Soluciones/20210903_101659.jpg"
                            style="border-radius: 0;"
                            height="350"
                        ></v-img>
                    </v-card>
                </v-col>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col md="4" cols="12" class="ma0 pa-0" style="color: black">
                    <v-card
                        :elevation="hover ? 16 : 0"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            class="imgCard ma0 pa-0"
                            src="@/assets/Soluciones/DSC_0083.jpg"
                            style="border-radius: 0;"
                            height="350"
                        ></v-img>
                    </v-card>
                </v-col>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col md="4" cols="12" class="ma0 pa-0" style="color: black">
                    <v-card
                        :elevation="hover ? 16 : 0"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            class="imgCard ma0 pa-0"
                            src="@/assets/Soluciones/20210903_101827.png"
                            style="border-radius: 0;"
                            height="350"
                        ></v-img>
                    </v-card>
                </v-col>
            </v-hover>

            <v-container style="height: 40px;"></v-container>
        </v-row>

        <!-- MOBILE -->
        <v-row class="center mobile">
            <v-col align="center" class="mb-6">
                <h1 style="color: #E13F2A">SOLUCIONES</h1>
                <h3 style="color: #892A43"> ¿QUÉ HACEMOS Y CÓMO LO HACEMOS?</h3>
            </v-col>
        </v-row>

        <v-row class="ma-0 pa-0 mobile">
            <v-row>
                <v-col align="center">
                    <iframe
                        src="https://player.vimeo.com/video/646965303?h=90c1efe31"
                        width="360"
                        height="240"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </v-col>
<!--                <v-img c src="@/assets/Soluciones/large-inkjet-printer-working-multicolor-on-vinyl-banner.jpg"></v-img>-->
            </v-row>

            <v-spacer></v-spacer>

            <v-col class="mt-5 mb-5">
                <v-container fill-height class="center">
                    <v-row justify="center" align="center">
                        <v-col cols="12">

                            <h4 style="font-weight: normal">
                                Nuestra tecnología de última generación nos permite ofrecer una calidad de impresión a
                                nivel internacional, agilizando procesos de producción y entrega, pero sobre todo
                                ofreciendo una auténtica experiencia visual para el consumidor de nuestros clientes.
                            </h4>

                        </v-col>
                    </v-row>
                </v-container>
            </v-col>

            <v-row justify="center" align="center">
                <v-img src="@/assets/Soluciones/DSC_0078.jpg"></v-img>
            </v-row>

        </v-row>

        <v-row class="mx-0 mb-6 mr-1 mobile" justify="center">
            <v-col class="mt-5 mb-5">
                <v-container fill-height>
                    <v-row justify="center" align="center">
                        <v-col>
                            <h4 style="font-weight: normal">
                                Redefinimos el modelo de servicio, integrando a nuestro equipo de trabajo especializado,
                                que hace efectiva la comunicación entre el cliente y el proceso de producción, brindando
                                de esta forma una experiencia satisfactoria.

                            </h4>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>

            <v-row class="mobile" justify="center">
                <v-row justify="center" align="center">
                    <v-img src="@/assets/Soluciones/DSC_0091.jpg"></v-img>
                </v-row>
            </v-row>

            <v-row class="center mobile" justify="center">
                <v-col class="mt-5">
                    <h3 style="color: #892A43"> ¿QUÉ OFERTA TENEMOS PARA TI? </h3>
                </v-col>
            </v-row>

            <v-spacer></v-spacer>

            <v-col>
                <v-container fill-height>
                    <v-row justify="center" align="center">
                        <v-col cols="12">

                            <h3 style="color: #892A43">
                                · Lona Mesh<br>
                                · Lona Front<br>
                                · Lona<br>
                                · Vinil adhesivo<br>
                                · Vinil estático<br>
                                · Vinil microperforado<br>
                                · Telas<br>
                                · Rotulación<br>
                                · Estireno<br>
                                · Backlight<br>

                            </h3>

                        </v-col>
                    </v-row>

                </v-container>


            </v-col>
        </v-row>

        <v-row style="overflow: hidden" class="center pa-0 ma-n3 mb-8 mobile">
            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col class="ma0 pa-0" style="color: black">
                    <v-card
                        :elevation="hover ? 16 : 0"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            class="imgCard ma0 pa-0"
                            src="@/assets/Soluciones/20210903_101659.jpg"
                            style="border-radius: 0;"
                            height="350"
                        ></v-img>
                    </v-card>
                </v-col>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col class="ma0 pa-0" style="color: black">
                    <v-card
                        :elevation="hover ? 16 : 0"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            class="imgCard ma0 pa-0"
                            src="@/assets/Soluciones/DSC_0083.jpg"
                            style="border-radius: 0;"
                            height="350"
                        ></v-img>
                    </v-card>
                </v-col>
            </v-hover>

            <v-hover v-slot="{ hover }" open-delay="200">
                <v-col class="ma0 pa-0" style="color: black">
                    <v-card
                        :elevation="hover ? 16 : 0"
                        :class="{ 'on-hover': hover }"
                        class="rounded-0 ma-0 pa-0"
                        style="overflow:hidden;"
                    >
                        <v-img
                            class="imgCard ma0 pa-0"
                            src="@/assets/Soluciones/20210903_101827.png"
                            style="border-radius: 0;"
                            height="350"
                        ></v-img>
                    </v-card>
                </v-col>
            </v-hover>

            <v-container style="height: 40px;"></v-container>
        </v-row>

    </v-container>
</template>

<script>
export default {
    name: "SolucionesSection",
    metaInfo() {
        let completeTitle = 'Troya - Soluciones';
        let completeURL = 'https://www.troya.com.mx/solutions';
        let imgURL = 'https://firebasestorage.googleapis.com/v0/b/troya-312e4.appspot.com/o/STICKY.png?alt=media&token=378d7682-a326-4c5b-aed8-6caf85c22556';

        return {
            title: completeTitle,
            meta: [
                {vmid: 'og:title', property: 'og:title', content: completeTitle},
                {vmid: 'twitter:title', property: 'twitter:title', content: completeTitle},
                {vmid: 'og:image', property: 'og:image', content: imgURL},
                {vmid: 'twitter:image', property: 'twitter:image', content: imgURL},
                {vmid: 'og:url', property: 'og:url', content: completeURL},
                {vmid: 'twitter:url', property: 'twitter:url', content: completeURL},
            ]
        }
    },
}
</script>

<style scoped>
.desktop {
    display: flex;
}

.mobile {
    display: none;
}

.imgCard:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .desktop {
        display: none;
        position: fixed;
    }

    .mobile {
        display: block;
    }
}
</style>