<template >
    <v-footer color="black">
        <v-row>
            <v-col>
                <divider class="ml-n4 mr-n4 mb-6"></divider>

                <v-row justify="center" class="pa-3 ma-1">
                    <v-img id="footer-logo" src="@/assets/Troya-Logo.png"></v-img>
                </v-row>

                <v-row justify="center" no-gutters>
                    <router-link to="/" style="text-decoration: none;">
                        <v-btn color="white" text rounded class="my-2">Inicio</v-btn>
                    </router-link>

                    <router-link to="/hermes" style="text-decoration: none;">
                        <v-btn color="white" text rounded class="my-2">Hermes</v-btn>
                    </router-link>

                    <router-link to="/solutions" style="text-decoration: none;">
                        <v-btn color="white" text rounded class="my-2">Soluciones</v-btn>
                    </router-link>

                    <router-link to="/team" style="text-decoration: none;">
                        <v-btn color="white" text rounded class="my-2">Colaboradores</v-btn>
                    </router-link>

                    <router-link to="/blog" style="text-decoration: none;">
                        <v-btn color="white" text rounded class="my-2">Blog</v-btn>
                    </router-link>

                </v-row>

                <v-col class="black lighten-2 py-4 text-center white--text mx-auto" cols="12">
                    <v-row justify="center" align="center">
                        <v-col cols="12" md="6" align="right" justify="center" id="mail">
                            <a href="mailto:contacto@troya.com.mx" style="text-decoration: none;">
                                <p style="color: white; text-decoration-color: white;">contacto@troya.com.mx</p>
                            </a>
                        </v-col>
                        <v-col cols="12" md="6" align="left" justify="center" class="mt-n3" id="sn-buttons">
                            <v-btn dark fab icon elevation="0" target="_blank" href="https://www.facebook.com/TroyaDigitalPrinting/"><v-icon color="#FFF" large >mdi-facebook</v-icon></v-btn>
                            <v-btn dark fab icon elevation="0" target="_blank" href="https://www.instagram.com/troyadigitalprinting/"><v-icon color="#FFF" large >mdi-instagram</v-icon></v-btn>
                            <v-btn dark fab icon elevation="0" target="_blank" href="https://www.linkedin.com/company/troyadigitalprinting/"><v-icon color="#FFF" large>mdi-linkedin</v-icon></v-btn>
<!--                            <v-btn dark fab icon elevation="0" target="_blank" href="https://www.facebook.com/TroyaDigitalPrinting/"><v-icon color="#FFF" large>mdi-youtube</v-icon></v-btn>-->
                        </v-col>
                    </v-row>
                </v-col>

                <v-col class="black lighten-2 py-4 text-center white--text" cols="12">
                    <strong>MÉXICO, {{ new Date().getFullYear() }} </strong>
                </v-col>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
import divider from "../Common_Components/divider";

export default {
    name: "FooterSection",
    components: {
        divider
    }
}
</script>

<style scoped>
#footer-logo {
    max-width: 200px;
}

@media only screen and (max-width: 600px) {
    #footer-logo {
        max-width: 150px;
    }

    #mail {
        margin-top: 20px;
        align-content: center;
        align-items: center;
        text-align: center;
        alignment: center;
    }

    #sn-buttons {
        margin-bottom: 20px;
        align-content: center;
        align-items: center;
        text-align: center;
        alignment: center;
    }
}

</style>